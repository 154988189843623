<template>
  <div class="page">
    <div class="row">
      <div class="col-12">
        <b-card title="Recent Leads">
          <b-table
            responsive="sm"
            :items="leads"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            striped
          >
            <template #cell(name)="data">
              <router-link :to="'leads/' + data.item.id">
                {{ data.value }}
              </router-link>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item :to="'/leads/' + data.item.id">
                  <feather-icon
                    icon="CornerDownRightIcon"
                    class="mr-50"
                  />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item :to="'/leads/' + data.item.id + '/edit'">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            class="d-flex justify-content-center"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          />
          <b-button
            variant="outline-primary"
            class="float-right"
            icon="PlusIcon"
            @click="$router.push({ name: 'create-lead' })"
          >
            Add Lead
          </b-button>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <b-card :title="'Most Popular Cities'">
          <b-dropdown
            id="dropdown-1"
            :text="selectedChartOption"
            variant="outline-primary"
          >
            <b-dropdown-item @click="onChartOptClick('In Percentage')">
              <span>In Percentage</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onChartOptClick('In Count')">
              <span>In Count</span>
            </b-dropdown-item>
          </b-dropdown>
          <Popular
            :data="mostPopular.cities"
            :option="selectedChartOption"
            title="Cities"
          />
        </b-card>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <b-card :title="'Most Popular Sub Areas'">
          <b-dropdown
            id="dropdown-1"
            :text="selectedChartOption"
            variant="outline-primary"
          >
            <b-dropdown-item @click="onChartOptClick('In Percentage')">
              <span>In Percentage</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onChartOptClick('In Count')">
              <span>In Count</span>
            </b-dropdown-item>
          </b-dropdown>
          <Popular
            :data="mostPopular.subAreas"
            :option="selectedChartOption"
            title="Subareas"
          />
        </b-card>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <b-card :title="'Most Popular Property Types'">
          <b-dropdown
            id="dropdown-1"
            :text="selectedChartOption"
            variant="outline-primary"
          >
            <b-dropdown-item @click="onChartOptClick('In Percentage')">
              <span>In Percentage</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onChartOptClick('In Count')">
              <span>In Count</span>
            </b-dropdown-item>
          </b-dropdown>
          <Popular
            :data="mostPopular.types"
            :option="selectedChartOption"
            title="Types"
          />
        </b-card>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Popular from './components/Popular.vue'

export default {
  components: {
    BCard, BTable, Popular, BButton, BDropdown, BDropdownItem, BPagination,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      mostPopular: {
        cities: [],
        subAreas: [],
        types: [],
      },
      fields: ['name', 'email', 'phone', 'type', { key: 'updated', sortable: true }, 'actions'],
      leads: [],
      cityTotal: 0,
      subareaTotal: 0,
      typeTotal: 0,
      selectedChartOption: 'In Percentage',
    }
  },
  computed: {
    rows() {
      return this.leads.length
    },
  },
  created() {
    if (this.$store.state.clientInfo.detail.website) {
      this.$store.dispatch('leads/getLeads', { id: '', website: this.$store.state.clientInfo.detail.website }).then(response => {
        console.log(response)
        this.getStats(response.data)
      }).catch(err => {
        console.error(err)
      })
    }

    this.$store.dispatch('leads/getLeadUsers').then(response => {
      this.leads = response.data.data.map(data => {
        return {
          id: data.id,
          name: data.first_name + ' ' + data.last_name,
          email: data.email,
          phone: data.phone,
          type: data.type,
          updated: data.updated_at ? this.formatDate(data.updated_at) : null,
        }
      })
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString('en-CA')
    },
    getStats(data) {
      const cities = []
      const subareas = []
      const types = []
      data.forEach(single => {
        if (single.city && cities[single.city]) {
          cities[single.city]++
        } else if (single.city) {
          cities[single.city] = 1
        }
        if (single.subarea && subareas[single.subarea]) {
          subareas[single.subarea]++
        } else if (single.subarea) {
          subareas[single.subarea] = 1
        }
        if (single.type && types[single.type]) {
          types[single.type]++
        } else if (single.type) {
          types[single.type] = 1
        }
      })

      Object.keys(cities).forEach(key => {
        this.cityTotal = this.cityTotal + cities[key]
      })

      Object.keys(cities).forEach(key => {
        this.mostPopular.cities.push({ key, value: ((cities[key] / this.cityTotal) * 100).toFixed(2), count: cities[key] })
      })
      Object.keys(subareas).forEach(key => {
        this.subareaTotal = this.subareaTotal + subareas[key]
      })
      Object.keys(subareas).forEach(key => {
        this.mostPopular.subAreas.push({ key, value: ((subareas[key] / this.subareaTotal) * 100).toFixed(2), count: subareas[key] })
      })
      // this.subareaTotal = subareaTotal
      Object.keys(types).forEach(key => {
        this.typeTotal = this.typeTotal + types[key]
      })
      Object.keys(types).forEach(key => {
        this.mostPopular.types.push({ key, value: ((types[key] / this.typeTotal) * 100).toFixed(2), count: types[key] })
      })
    },
    onChartOptClick(opt) {
      this.selectedChartOption = opt
    },
  },
}
</script>

<style lang="scss">
.popular{
  align-items:center;
  padding:5px 0;
  .title{
    width:120px;
  }
  .progressBar{
    width:calc(100% - 190px);
  }
  .percent{
    width:80px;
    text-align:right;
  }
}
</style>
